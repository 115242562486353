import SetupForm from "./Form/SetupForm";
import Loading from "./Loading/LoadingScreen";
import Modal from "./Modal/Modal.jsx";
import { useGlobalContext } from "./Context/Context.jsx";
import {T} from './PopUpJK.js';

function App() {
 
  const {
    waiting,
    loading,
    questions,
    index,
    nextQuestion,
    checkAnswers, 
    expl,
    reset,
    ansRef,
  } = useGlobalContext();
  
  if (waiting) {
    return <SetupForm />;
  }
  if (loading) {
    return <Loading />;
  }

  const { incorrect_answers, correct_answer, question } =  questions[index];
  let answers = [...incorrect_answers];
    const tempIndex = Math.floor(Math.random() * 4);
    if (tempIndex === 3) {
      answers.push(correct_answer);
    } else {
      answers.push(answers[tempIndex]);
      answers[tempIndex] = correct_answer;
    };

    if (ansRef.current.idx !== index) {
      ansRef.current = {idx:index, ans:answers};
    }

  const explain = (expl) => {
    if (expl.idx === index) {
      if (expl.result) {
        return <h4 className='exple'>Correct!</h4>;
      } else {
        return <h4  className='exple'>{expl.answer ? `Wrong! The correct answer is ${expl.answer}.` : ''}</h4>;
      }
    }
    if (expl.idx === index - 1) {
      return null;
    } 
    return 'error';
  }

  const points = (expl) => {
    if (expl.idx === index - 1) {
      return `${expl.corrects}/${index}`;
    } 
    if (expl.idx === index) {
        return `${expl.corrects}/${index + 1}`;
    }
    return 'error';
  };

  const Question = `(Q ${index + 1}) ` + question;
  return (
    <main> 
      <Modal />
      <section className="quiz">
        {/*console.log(questions)*/}
       
        <article className="container">
          <h2 dangerouslySetInnerHTML={{ __html: Question }} />
          <div className="btn-container">
            {ansRef.current.ans.map((answer, idx) => {
              return (
                <button
                  key={idx}
                  className={expl.idx === index ? "answered-btn" : "answer-btn"}
                  onClick={() =>checkAnswers(correct_answer, answer, index)}
                  dangerouslySetInnerHTML={{ __html: answer }}
                />
              );
            })}
            
          </div>
          <br />
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center' }}>     
          <h4 className="correct-answers">correct answers: <T r={points(expl)} s='correct answers out of questions so far' w={160}/></h4>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {explain(expl)}
          </div>
          <div style={{display:'flex', justifyContent:'center'}}>
            <button className={expl.idx === index ? 'a' : 'a1'} onClick={nextQuestion} >
              <T r={<pre>    next    </pre>} s={expl.idx === index ? 'Ready for next question' : 'Choose an answer'} w={100} />
            </button>
            &nbsp;
            <button className='b' onClick={reset}>
              < T r={<pre>    quit    </pre>} s='Start a new quize game' w={100} />
            </button>
        </div>
        </article>      
      </section>
    </main>
  );
}
export default App;
