const aboutText = 
<>
This is a quiz application for sports, history, and politics. <br />
1. The data come from `https://opentdb.com/api.php?... <br />
2. Check the tooltips of buttons to play.<br />
3. When you have the right answer, confetti will be showered over the question.<br />
4. When you finish all questions, the last page will show you the result.<br />
4. Any time you want to play for another batch of quizzes, press the "Quit" button. <br />
</>;

export default aboutText;