import { useGlobalContext } from "../Context/Context";
import aboutText from "./aboutText";

const About = () => {
  const { about, setAbout} = useGlobalContext();

  return (
    <div className={`${about ? "modal-container isOpen" : "modal-container"}`}
      style={{display:'flex', flexDirection:'column',justifyContent:'center'}}
    >
        <p style={{color:'white'}}> 
          {aboutText}
        </p>
        <button className="close-btn" onClick={()=>setAbout(false)}>
          close
        </button>
    </div>
  );
};
export default About;
