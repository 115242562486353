import { useState, useContext, createContext, useRef } from "react";
import axios from "axios";
import JSConfetti from 'js-confetti';
import {PUC} from '../PopUpJK';
const jsConfetti = new JSConfetti();

const table = {
  sports: 21,
  history: 23,
  politics: 24,
};

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [waiting, setWaiting] = useState(true); //waiting
  const [loading, setLoading] = useState(false); //loading
  const [error, setError] = useState(false); //error
  const [modal, setModal] = useState(false);
  const [about, setAbout] = useState(false);

  const [questions, setQuestions] = useState([]); //questions
  const [index, setIndex] = useState(0); //index
  const [expl, setExpl] = useState({idx: -1, result: null, answer:'', corrects: 0});
  const [quiz, setQuiz] = useState({amount: 10, category: "sports",difficulty: "easy"});
  const [confetti, setConfetti] = useState(false); 

  const [, setP] = PUC();
  //fetchQuestions

  var ansRef = useRef({idx: -1, ans:[]});

  const reset = () => {
    setWaiting(true); //waiting
    setLoading(false); //loading
    setQuestions([]); //questions
    setIndex(0); //index
    setError(false); //error
    setExpl({idx: -1, result: null, answer:'',  corrects: 0});
    setQuiz({amount: 10, category: "sports", difficulty: "easy",});
    setModal(false);
    setAbout(false);
    ansRef.current = {idx: -1, ans:[]};
  };

  const fetchQuestions = async (url) => {
    setLoading(true);
    setWaiting(false);
    const response = await axios(url).catch((err) => console.log(err));

    if (response) {
      const data = response.data.results;
      if (data.length > 0) {
        setQuestions(data);
        setLoading(false);
        setWaiting(false);
        setError(false);
      } else {
        setWaiting(true);
        setLoading(true);
      }
    } else {
      setWaiting(true);
    }
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    if (confetti) {
      jsConfetti.clearCanvas();
      console.log('jsConfetti.clear');
      setConfetti(false);
    }
    reset();
  };

  const nextIndex = () => {
    setIndex((oldIndex) => {
      const index = oldIndex + 1;
      if (index >= questions.length) {
        openModal();
        return 0;
      } else {
        return index;
      }
    }); 
  };

  const CBX = () => {
    setExpl({idx: index, result: false, answer: '', corrects: expl.corrects });
    nextIndex();
  };

  const CBO = () => { };

  const nextQuestion = () => {
    if (expl.idx  === index) {
      if (confetti) {
        jsConfetti.clearCanvas();
        console.log('jsConfetti.clear');
        setConfetti(false);
      }
      nextIndex();
    } else {
      setP({option: 1, open:true, message:'Did not answer. Press "O" to answer. ' + 
      'To go over to Next question, "X".', CBO:CBO, CBX:CBX});
    }  
  };

  /*
   const nextQuestion = () => {
    setIndex((oldIndex) => {
      const index = oldIndex + 1;
      if (index >= questions.length) {
        openModal();
        return 0;
      } else {
        return index;
      }
    });
  };*/

  const checkAnswers = (correct, answer, index) => {
    if (index === expl.idx) { //expl.idx  === index
      return;
    }
    if (correct === answer) {
      setExpl({idx: index, result: true, answer: correct, corrects: expl.corrects + 1});
      jsConfetti.addConfetti();
      setConfetti(true);
        console.log('jsConfetti.add');
    } else {
      setExpl({idx: index, result: false, answer: correct, corrects: expl.corrects });
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuiz({ ...quiz, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { amount, difficulty, category } = quiz;
    const url = `https://opentdb.com/api.php?amount=${amount}&difficulty=${difficulty}&category=${table[category]}&type=multiple`;
    fetchQuestions(url);
  };

  return (
    <AppContext.Provider
      value={{
        waiting,
        loading,
        questions,
        index,
        error,
        modal,
        nextQuestion,
        checkAnswers,
        closeModal,
        quiz,
        handleChange,
        handleSubmit,
        expl,
        reset,
        about, 
        setAbout,
        jsConfetti,
        ansRef,
        confetti, 
        setConfetti,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
