
import { useGlobalContext } from "../Context/Context";

const Modal = (m) => {
  const { modal, closeModal, expl, questions, jsConfetti, setConfetti, } = useGlobalContext();
  const percentage = ((expl.corrects / questions.length) * 100).toFixed(0);
  if(modal && (percentage >= 60)) {
      jsConfetti.addConfetti();
      setConfetti(true);
      console.log('jsConfetti.add');
  }

  return (
    <div className={`${modal ? "modal-container isOpen" : "modal-container"}`}>
      <div className="modal-content">
        <h2>{percentage >= 60 ? 'Congrats!' : 'Nice try! Good luck, next time!'}</h2>
        <p>You answered {percentage}% right! </p>
        <button className="close-btn" onClick={closeModal}>
          play again
        </button>
      </div>
    </div>
  );
};
export default Modal;
